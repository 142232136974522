import React from 'react'
import BGImg7 from '../../assets/images/shapes/about-three-s-1.png'
import resource from '../../assets/images/background/about-1.png'

function Benifit() {
    return (
        <>
            <section className=" section-padding--top about-three about-three--home-one">
                <div className="about-three__shape wow fadeInRight" data-wow-duration="1500ms"
                    style={{ backgroundImage: `url(${BGImg7})` }}>
                </div>
                <div className="container">
                    <div className="row gutter-y-60">
                        <div className="col-lg-6">
                            <div className="about-three__image">
                                <img src={resource} className="wow fadeInUp"
                                    data-wow-duration="1500ms" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-three__content">
                                <div className="section-title ">
                                    <p className="section-title__text">Company Benefits</p>
                                    <h2 className="section-title__title">Why You Should Choose
                                        Our Services</h2>
                                </div>
                                <div className="about-three__text">Choose The Elite Vision to have custom software solutions for your
                                    business with the most reasonable price.</div>
                                <ul className="about-three__list">
                                    <li className="about-three__list__item">
                                        <div className="about-three__list__icon">
                                            <i className="icon-cloud"></i>
                                        </div>
                                        <div className="about-three__list__content">
                                            <h3 className="about-three__list__title">
                                                Cloud Based Services
                                            </h3>
                                            <p className="about-three__list__text">Services address a range of simply free text
                                                application and infrastructure needs.</p>
                                        </div>
                                    </li>
                                    <li className="about-three__list__item">
                                        <div className="about-three__list__icon">
                                            <i className="icon-group"></i>
                                        </div>
                                        <div className="about-three__list__content">
                                            <h3 className="about-three__list__title">
                                                Expert Team Members
                                            </h3>
                                            <p className="about-three__list__text">Services address a range of simply free text
                                                application and infrastructure needs.</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Benifit