import React from 'react'
import Breadcumb from '../Breadcumb/Main'
import Map from '../Mencontact/Map'
import With from '../Mencontact/With'

const Contact = () => {
    return (
        <>
            <Breadcumb Title="Contact" Breadcumb="CONTACT" />
            <With />
            {/* <Map/> */}
        </>
    )
}

export default Contact