import React from 'react'
import { Link } from 'react-router-dom'
// import Logo from '../../assets/images/logo-light.png'
import Logo from '../../assets/logos/logo-png.png'

const Footer = () => {

    return (
        <>
            <div className={`footer-main section-padding--bottom section-padding--top`}>
                <div className="container">
                    <div className="row gutter-y-30">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="footer-widget footer-widget--about">
                                <Link to="/" className="footer-widget__logo">
                                    <img src={Logo} width="200" height="90" alt="The Elite Vision" />
                                </Link>
                                <p className="footer-widget__text">We provide excellent IT services and consulting services.</p>
                                <ul className="footer-widget__social">
                                    <li><Link to="#"><i className="fab fa-linkedin"></i></Link></li>
                                    <li><a href="https://www.facebook.com/theelitevision3" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                    {/* <li><Link to="#"><i className="fab fa-pinterest-p"></i></Link></li> */}
                                    <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="footer-widget footer-widget--links">
                                <h3 className="footer-widget__title">Useful Links</h3>
                                <ul className="footer-widget__links">
                                    <li>
                                        <a href="#home">Home</a>
                                    </li>
                                    <li>
                                        <a href="#">Career</a>
                                    </li>
                                    <li>
                                        <a href="https://forms.gle/wGBqB3rZBPTnLdBC9" target="_blank">Training</a>
                                    </li>
                                    <li>
                                        <Link href="/contact">Contact</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="footer-widget footer-widget--newsletter">
                                <h3 className="footer-widget__title">Subscribe</h3>
                                <p className="footer-widget__text">Subscribe for bootcamp notification</p>

                                <form action="#" className="footer-widget__newsletter mc-form"
                                    data-url="ADD_YOUR_MAILCHIMP_FORM_URL_HERE">
                                    <input type="email" name="EMAIL" placeholder="Enter Your Email" />
                                    <button className="thm-btn footer-widget__newsletter__btn"><span>Subscribe</span></button>
                                </form>
                                <div className="mc-form__response"></div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="footer-widget footer-widget--contact">
                                <h3 className="footer-widget__title">Contact</h3>
                                <ul className="footer-widget__contact">
                                    <li>
                                        <i className="fa fa-phone"></i>
                                        <Link to="tel:+14697125708">+1 (469) 712-5708</Link>
                                    </li>
                                    <li>
                                        <i className="fa fa-envelope"></i>
                                        <Link to="mailto:it-services@theelitevision.com">it-services@theelitevision.com</Link>
                                    </li>
                                    <li>
                                        <i className="fa fa-map-marker-alt"></i>
                                        7150 Holland Dr, Suite, Frisco TX 75035

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <p>&copy; All Copyright Reserved | <span className="dynamic-year"></span> {new Date().getUTCFullYear()} TheEliteVision.com</p>
                </div>
            </div>
        </>
    )
}

export default Footer