import React from 'react'
import { Link } from 'react-router-dom'
import BGImg5 from '../../assets/images/shapes/testi-bg-1-1.png'
import test01 from '../../assets/images/clients/client-1.png'
import test02 from '../../assets/images/clients/client-2.png'

function Trusted() {

    return (
        <>
            <section id="clients" className="section-padding--bottom section-padding--top testimonials-two background-repeat-no background-position-top-center"
                style={{ backgroundImage: `url(${BGImg5})` }}>
                <div className="container">
                    <div className="row gutter-y-60">
                        <div className="col-lg-5">
                            <div className="testimonials-two__content">
                                <div className="section-title ">
                                    <p className="section-title__text">Our clients</p>
                                    <h2 className="section-title__title">
                                        We Are Trusted Worldwide Peoples
                                    </h2>
                                </div>
                                <div className="testimonials-two__content__text">
                                    We are skilled at delivering personalized solutions to diverse industries,
                                    whether assisting a startup's ideation or fueling an enterprise's expansion.
                                </div>
                                <div className="testimonials-two__content__text">
                                    We have served clients from multiple domains/industries like finance, ecommerce, banking, fashion, telecommunication and educational sector
                                    for product and business development from scratch to production ready serving wide range of customers.
                                </div>
                                {/* <Link to="/about" className="thm-btn testimonials-two__content__btn" >
                                    <span>View All feedbacks</span>
                                </Link> */}
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="testimonials-two__items">
                                <div className="row gutter-y-30">
                                    <div className="col-lg-12">
                                        <div className="testimonials-one-card">
                                            <div className="testimonials-one-card__image">
                                                <img src={test01} alt="" />
                                            </div>
                                            <div className="testimonials-one-card__content">
                                                <div className="testimonials-one-card__text">
                                                    The Elite Vision is an excellent consultant company with staff that is friendly, professional and most importantly driven by positive results. I offer my full recommendation.
                                                </div>
                                                <h3 className="testimonials-one-card__title">
                                                    John Hines
                                                </h3>
                                                {/* <p className="testimonials-one-card__designation">
                                                    Ul - UX Designer
                                                </p> */}

                                                <i className="icon-right-quote testimonials-one-card__icon"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="testimonials-one-card">
                                            <div className="testimonials-one-card__image">
                                                <img src={test02} alt="" />
                                            </div>
                                            <div className="testimonials-one-card__content">
                                                <div className="testimonials-one-card__text">
                                                    The Elite Vision delivered within the client's provided roadmap, extensively supporting the project. The team excellently communicated improvements, issues, and progress of every deliverable.
                                                    Furthermore, their openness and flexibility were the highlights of the collaboration.
                                                </div>
                                                <h3 className="testimonials-one-card__title">
                                                    Jessica Brown
                                                </h3>
                                                {/* <p className="testimonials-one-card__designation">
                                                    Ul - UX Designer
                                                </p> */}
                                                <i className="icon-right-quote testimonials-one-card__icon"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Trusted;
