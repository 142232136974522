import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import img1 from '../../assets/images/resources/about-five-1-1.jpg'
import img2 from '../../assets/images/resources/about-five-1-2.jpg'
import AnimatedNumber from "animated-number-react"

function Networking() {

    const [ytShow, setytShow] = useState(false)

    return (
        <>
            <section id="about" className="section-padding--bottom section-padding--top about-five">
                <div className="container">
                    <div className="row gutter-y-60">
                        <div className="col-lg-6">
                            <div className="about-five__images">
                                <div className="about-five__images__shape"></div>
                                <img src={img1} className="wow fadeInUp"
                                    data-wow-duration="1500ms" alt="" />
                                <img src={img2} alt="" />
                                <div className="about-five__video">
                                    <span to="#" className="video-popup">
                                        <i className="fa fa-play"></i>
                                    </span>
                                </div>
                                <div className="about-five__images__caption count-box wow fadeInUp" data-wow-duration="1500ms">
                                    <span className="count-text"><AnimatedNumber value={5} duration={2500} formatValue={(v) => Math.round(v)} /></span>
                                    Years <br />
                                    Experience
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-five__content">
                                <div className="section-title ">
                                    <p className="section-title__text">About Company</p>
                                    <h2 className="section-title__title">
                                        Best IT Services and consulting company
                                    </h2>

                                </div>
                                <div className="about-five__text">
                                    The Elite Vision believes in providing customized solutions in accordance with your specific business needs, which is why our experts indulge in a thorough analysis of
                                    your business processes and goals. We then use the right resources and technologies to tailor our products and services for your business, thus helping you accomplish your vision.

                                </div>
                                <div className="about-five__text">
                                    At the Elite Vision, we firmly advocate systematic planning and meticulous execution.
                                    In order to ensure improved efficiency, we follow a methodical plan right from the start to the finish of your project.
                                    So, you can knock at our doors with confidence for any of your business needs.
                                </div>
                                <ul className="about-five__box">
                                    <li className="about-five__box__item gray-bg">
                                        <i className="about-five__box__icon icon-consulting"></i>
                                        <div className="about-five__box__content">
                                            <h3 className="about-five__box__title">
                                                IT Consultant
                                            </h3>
                                            <p className="about-five__box__text">Smarter Solutions</p>
                                        </div>
                                    </li>
                                    <li className="about-five__box__item gray-bg">
                                        <i className="about-five__box__icon icon-chip"></i>
                                        <div className="about-five__box__content">
                                            <h3 className="about-five__box__title"><Link to="/team">IT Specialist</Link></h3>
                                            <p className="about-five__box__text">Faster Solutions</p>
                                        </div>
                                    </li>
                                </ul>
                                {/* <div className="about-four__meta">
                                    <div className="about-four__author">
                                        <img src="assets/images/resources/about-four-author-1.jpg" alt="" />
                                        <div className="about-four__author__content">
                                            <h3 className="about-four__author__title">Michele Morrone</h3>
                                            <div className="about-four__author__designation">Founder CEO</div>
                                        </div>
                                    </div>
                                    <Link to="/service-01" className="thm-btn about-four__btn"><span>Learn More</span></Link>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {ytShow &&
                <div className="YouTubePopUp-Wrap">
                    <div className="YouTubePopUp-Content">
                        <span className="YouTubePopUp-Close" onClick={() => setytShow(false)}></span>
                        <iframe src="https://www.youtube.com/embed/rzfmZC3kg3M?autoplay=1" title='ytvideo' allowFullScreen></iframe>
                    </div>
                </div>
            }
        </>
    )
}

export default Networking