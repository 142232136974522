import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Logo from '../../assets/logos/logo-png.png'
import WOW from 'wowjs'

const Navbar = () => {
    const location = useLocation()
    const path = location.pathname
    const [sticky, setSticky] = useState(false)
    const [search, setSearch] = useState(false)
    const [mobile, setmobile] = useState(false)

    const [menu, setmenu] = useState({})
    // const [home, setHome] = useState(false)
    // const [header, setheader] = useState(false)
    // const [pages, setpages] = useState(false)
    // const [service, setservice] = useState(false)
    // const [project, setproject] = useState(false)
    // const [blog, setblog] = useState(false)

    const activeMenu = () => {
        if (path === "/") {
            setmenu({ home: true })
        } else if (path === "/about") {
            setmenu({ pages: true })
        } else if (path === "/services") {
            setmenu({ services: true })
        } else if (path === "/blog") {
            setmenu({ blog: true })
        } else if (path === "/contact") {
            setmenu({ contact: true })
        } else {
            setmenu({ home: true })
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, []);

    useEffect(() => {
        window.scroll(0, 0)
        new WOW.WOW({
            live: false
        }).init();
        activeMenu()
    }, [path])

    const isSticky = () => {
        const scrollTop = window.scrollY;
        scrollTop >= 141 ? setSticky(true) : setSticky(false);
    }

    return (
        <>
            <div className="topbar">
                <div className="container-fluid">
                    <p className="topbar__text">Welcome to the Elite Vision IT Solutions & Services</p>
                    <ul className="topbar__info">
                        <li>
                            <i className="fa fa-envelope"></i>
                            <Link to="mailto:it-services@theelitevision.com">it-services@theelitevision.com</Link>
                        </li>
                        <li>
                            <i className="fa fa-map-marker"></i>
                            7150 Holland Dr, Suite, Frisco TX 75035

                        </li>
                    </ul>
                    <ul className="topbar__social">
                        <li><Link to="#"><i className="fab fa-linkedin"></i></Link></li>
                        <li><a href="https://www.facebook.com/theelitevision3" target="_blank"><i className="fab fa-facebook"></i></a></li>
                        <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                    </ul>
                </div>
            </div>
            <nav className={`main-menu sticky-header ${sticky && "sticky-header--cloned sticky-fixed"}`}>
                <div className="container-fluid">
                    <div className="main-menu__logo">
                        <Link to="/">
                            <img src={Logo} width="200" height="89" alt="TheEliteVision" />
                        </Link>
                    </div>

                    <ul className="main-menu__list">
                        <li className={`menu-item-has-children ${menu.home && "current"}`}>
                            <Link to="/">Home</Link>
                        </li>
                        <li className={`menu-item-has-children ${menu.pages && "current"}`}>
                            <a href="#about">About Us</a>
                        </li>
                        <li className={`menu-item-has-children ${menu.services && "current"}`}>
                            <a href="#services">Services</a>
                        </li>
                        <li className={`menu-item-has-children ${menu.project && "current"}`}>
                            <a href="#clients">Clients</a>
                        </li>
                        <li className={`menu-item-has-children ${menu.blog && "current"}`}>
                            <a href="https://forms.gle/wGBqB3rZBPTnLdBC9" target="_blank">Training</a>
                        </li>
                        <li className={`menu-item-has-children ${menu.contact && "current"}`}>
                            <Link to="/contact">Contact</Link>
                        </li>
                    </ul>

                    <div className="main-menu__right">
                        <Link to="#" className="mobile-nav__toggler" onClick={() => setmobile(true)}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </Link>
                        {/* <Link to="#" onClick={() => setSearch(true)} className="search-toggler">
                            <i className="icon-magnifying-glass"></i>
                        </Link> */}
                        <Link to="tel:+14697125708" className="main-menu__cta">
                            <i className="fa fa-phone-alt"></i>
                            <span className="main-menu__cta__text">
                                <b>+1 (469) 712-5708</b>
                                24/7 IT support
                            </span>
                        </Link>
                    </div>

                </div>
            </nav>
            {/* <div className={`search-popup ${search && "active"}`}>
                <div className="search-popup__overlay search-toggler" onClick={() => setSearch(false)}></div>
                <div className="search-popup__content">
                    <form action="#">
                        <label htmlFor="search" className="sr-only">search here</label>
                        <input type="text" id="search" placeholder="Search Here..." />
                        <button type="submit" aria-label="search submit" className="thm-btn">
                            <span><i className="icon-magnifying-glass"></i></span>
                        </button>
                    </form>
                </div>
            </div> */}
            <div className={`mobile-nav__wrapper ${mobile && "expanded"}`}>
                <div className="mobile-nav__overlay mobile-nav__toggler" onClick={() => setmobile(false)}></div>
                <div className="mobile-nav__content">
                    <Link to="#" onClick={() => setmobile(false)} className="mobile-nav__close mobile-nav__toggler">
                        <span></span>
                        <span></span>
                    </Link>

                    <div className="logo-box">
                        <Link to="/" aria-label="logo image"><img src={Logo} width="200" height="89" alt="TheEliteVision" /></Link>
                    </div>
                    <div className="mobile-nav__container"><ul className="mobile-menu__list">
                        <li className={`menu-item-has-children ${menu.home ? "current" : ""}`}>
                            <Link to="/">Home</Link>
                        </li>
                        <li className={`menu-item-has-children ${menu.pages ? "current" : ""}`}>
                            {/* <Link to="/about">About Us</Link> */}
                            <a href="#about">About Us</a>

                        </li>
                        <li className={`menu-item-has-children ${menu.services ? "current" : ""}`}>
                            {/* <Link to="/services">Services</Link> */}
                            <a href="#services">Services</a>
                        </li>
                        {/* <li className={`menu-item-has-children ${menu.project ? "current" : ""}`}>
                            <Link to="/project">Projects</Link>
                        </li> */}
                        <li className={`menu-item-has-children ${menu.blog ? "current" : ""}`}>
                            {/* <Link to="/blog">Blog</Link> */}
                            <a href="#clients">Clients</a>
                        </li>
                        <li>
                            <a href="https://forms.gle/wGBqB3rZBPTnLdBC9" target="_blank">Training</a>
                        </li>

                        <li><Link to="/contact">Contact</Link></li>
                    </ul></div>

                    <ul className="mobile-nav__contact list-unstyled">
                        <li>
                            <i className="fa fa-phone"></i>
                            <a href="tel:+14697125708">+1 (469) 712-5708</a>
                        </li>
                        <li>
                            <i className="fa fa-envelope"></i>
                            <a href="mailto:it-services@theelitevision.com">it-services@theelitevision.com</a>
                        </li>
                        <li>
                            <i className="fa fa-map-marker-alt"></i>
                            7150 Holland Dr, Suite,<br /> Frisco TX 75035
                        </li>
                    </ul>
                    <ul className="mobile-nav__social">
                        <li><Link to="/"><i className="fab fa-linkedin"></i></Link></li>
                        <li><a href="https://www.facebook.com/theelitevision3" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                        <li><Link to="/"><i className="fab fa-instagram"></i></Link></li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Navbar