import React from 'react'
import { Link } from 'react-router-dom'
import BGImg3 from '../../assets/images/shapes/service-four-bg-1-1.png'
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';

function Provide() {

    const options = {
        loop: false,
        margin: 0,
        items: 1,
        responsive: {
            0: {
                margin: 0,
                items: 1
            },
            576: {
                margin: 30,
                items: 2
            },
            768: {
                margin: 30,
                items: 3
            },
            992: {
                margin: 30,
                items: 4
            },
            1200: {
                margin: 30,
                items: 5
            }
        },
    };

    const services = [
        {
            icon: 'icon-new-product',
            title: 'Web App Development',
            text: 'Providing the solutions for non-IT businesses.',
            link: '/infrastructure-plan',
        },
        {
            icon: 'icon-new-product',
            title: 'Digital Marketing',
            text: 'Providing the solutions for non-IT businesses.',
            link: '/infrastructure-plan',
        },
        {
            icon: 'icon-protection',
            title: 'Mobile App Development',
            text: 'Providing the solutions for non-IT businesses.',
            link: '/infrastructure-plan',
        },
        {
            icon: 'icon-web-development',
            title: 'UI/UX Designing',
            text: 'Providing the solutions for non-IT businesses.',
            link: '/infrastructure-plan',
        },
        {
            icon: 'icon-analysis',
            title: 'Data Analysis',
            text: 'Providing the solutions for non-IT businesses.',
            link: '/infrastructure-plan',
        },
    ];

    return (
        <>
            <section id="services" className="section-padding--top service-four gray-bg section-padding-xl--bottom section-has-bottom-margin background-repeat-no background-position-top-right"
                style={{ backgroundImage: `url(${BGImg3})` }}>
                <div className="container">
                    <div className="section-title text-center">
                        <p className="section-title__text">Popular Services</p>
                        <h2 className="section-title__title">We Provide Our Client Best <br />IT Solution Services</h2>

                    </div>
                    <Swiper className="thm-owl__carousel thm-owl__carousel--with-shadow service-four__carousel"
                        {...options} slidesPerView={5} spaceBetween={30}>
                        {services.map((service, index) => (
                            <SwiperSlide className="item" key={index}>
                                <div className="service-card-three">
                                    <div className="service-card-three__icon">
                                        <i className={service.icon}></i>
                                    </div>
                                    <div className="service-card-three__content">
                                        <h3 className="service-card-three__title"><Link to={service.link}>{service.title}</Link></h3>
                                        <div className="service-card-three__text">{service.text}</div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </section>
        </>
    )
}

export default Provide
