import React from 'react'
import { Link } from 'react-router-dom'
import team01 from '../../assets/images/team/team-2-1.jpg'
import team02 from '../../assets/images/team/team-2-2.jpg'
import team03 from '../../assets/images/team/team-2-3.jpg'
import team04 from '../../assets/images/team/team-2-4.jpg'
import BGImg6 from '../../assets/images/shapes/team-home-bg-1-1.jpg'

function Team() {
    return (
        <>
            <section className="section-padding--bottom section-padding--top gray-bg background-size-cover"
                style={{ backgroundImage: `url(${BGImg6})` }}>
                <div className="container">
                    <div className="section-title text-center">
                        <p className="section-title__text">Our Team members</p>
                        <h2 className="section-title__title">Our Expert Person to Provide <br /> IT Solution Services</h2>
                    </div>
                    <div className="row gutter-y-30">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="team-card-one wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="000ms">
                                <div className="team-card-one__image">
                                    <img src={team01} alt="" />
                                    <div className="team-card-one__social">
                                        <ul className="team-card-one__social__links">
                                            <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-facebook"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-pinterest-p"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                                        </ul>
                                        <div className="team-card-one__social__icon">
                                            <i className="fa fa-share-alt"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-card-one__content">
                                    <h3 className="team-card-one__title"><Link to="#">Michelle Monaghan</Link></h3>

                                    <p className="team-card-one__designation">Designer</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="team-card-one wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="100ms">
                                <div className="team-card-one__image">
                                    <img src={team02} alt="" />
                                    <div className="team-card-one__social">
                                        <ul className="team-card-one__social__links">
                                            <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-facebook"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-pinterest-p"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                                        </ul>
                                        <div className="team-card-one__social__icon">
                                            <i className="fa fa-share-alt"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-card-one__content">
                                    <h3 className="team-card-one__title"><Link to="#">Jessica Brown</Link></h3>

                                    <p className="team-card-one__designation">Manager</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="team-card-one wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                                <div className="team-card-one__image">
                                    <img src={team03} alt="" />
                                    <div className="team-card-one__social">
                                        <ul className="team-card-one__social__links">
                                            <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-facebook"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-pinterest-p"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                                        </ul>
                                        <div className="team-card-one__social__icon">
                                            <i className="fa fa-share-alt"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-card-one__content">
                                    <h3 className="team-card-one__title"><Link to="#">Kevin Martin</Link></h3>

                                    <p className="team-card-one__designation">Developer</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="team-card-one wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="300ms">
                                <div className="team-card-one__image">
                                    <img src={team04} alt="" />
                                    <div className="team-card-one__social">
                                        <ul className="team-card-one__social__links">
                                            <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-facebook"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-pinterest-p"></i></Link></li>
                                            <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                                        </ul>
                                        <div className="team-card-one__social__icon">
                                            <i className="fa fa-share-alt"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-card-one__content">
                                    <h3 className="team-card-one__title"><Link to="#">Sarah Albert</Link></h3>

                                    <p className="team-card-one__designation">Director</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Team