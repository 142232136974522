import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import BG from '../../assets/images/background/contact-one-bg-1-1.png'
import { Resend } from 'resend';
import { enqueueSnackbar } from 'notistack';


function With() {

    const [contactData, setContactData] = useState({
        name: "",
        fromEmail: "",
        toEmail: "it-services@theelitevision.com",
        subject: "Regarding Business Consultant message",
        message: ""
    });

    const handleChange = (e) => {
        e.preventDefault();
        const key = e.target.name;
        const value = e.target.value;
        // console.log("target", e.target)
        setContactData(prevState => ({ ...prevState, [key]: value }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log("handleSubmit", contactData)
        try {

            const resend = new Resend("re_5rsRkcEb_CxFNwDE1bq2cC9UH9gtCYgz1");
            const { data, error } = await resend.emails.send({
                from: contactData.fromEmail,
                to: [contactData.toEmail],
                subject: `${contactData.subject} from ${contactData.name}`,
                html: `<p>${contactData.message}</p>`,
            });

            if (error) {
                throw error;
            }
            console.log("data", data);
            enqueueSnackbar("Email sent successfully", { variant: "success" })
        } catch (e) {
            enqueueSnackbar("Failed to send email, try again!", { variant: "error" });
            return console.log("error", e);
        }

    }

    return (
        <>
            <section className="contact-one section-padding--top section-padding--bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <form className="contact-one__form contact-form-validated" onSubmit={handleSubmit}>
                                <div className="section-title">
                                    <p className="section-title__text">Contact with us</p>
                                    <h2 className="section-title__title">Join Us To Get IT Free
                                        Consultations</h2>
                                </div>
                                <div className="row ">

                                    <div className="col-lg-6 col-md-12">
                                        <input type="text" placeholder="Your name" name="name" onChange={handleChange} value={contactData.name} />
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <input type="email" placeholder="Email address" name="fromEmail" onChange={handleChange} value={contactData.fromEmail} />
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <textarea name="message" placeholder="Write message" onChange={handleChange} value={contactData.message}></textarea>
                                    </div>
                                    <div className="col-md-12">
                                        <button className="thm-btn contact-one__btn" type="submit">
                                            <span>Send a message</span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <div className="result"></div>
                        </div>
                        <div className="col-lg-4">
                            <div className="contact-one__info wow fadeInRight" data-wow-duration="1500ms" style={{ backgroundImage: `url(${BG})` }}>
                                <div className="contact-one__item">
                                    <h3 className="contact-one__item__title">Address</h3>
                                    <p className="contact-one__item__text">
                                        7150 Holland Dr, Suite,  <br />
                                        Frisco TX 75035, US</p>
                                </div>
                                <div className="contact-one__item">
                                    <h3 className="contact-one__item__title">Phone</h3>
                                    <p className="contact-one__item__text">Mobile: <Link to="tel:+14697125708">+1 (469) 712-5708</Link>
                                    </p>
                                </div>
                                <div className="contact-one__item">
                                    <h3 className="contact-one__item__title">Email</h3>
                                    <p className="contact-one__item__text">
                                        <Link to="mailto:it-services@theelitevision.com">
                                            it-services@theelitevision.com
                                        </Link><br />
                                    </p>
                                </div>
                                <div className="contact-one__item">
                                    <ul className="contact-one__social">
                                        <li><Link to="#"><i className="fab fa-linkedin"></i></Link></li>
                                        <li><a href="https://www.facebook.com/theelitevision3" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                        <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default With