import React from 'react'
import { Link } from 'react-router-dom'
import BGImg from '../../assets/images/background/cta-two-bg-1-1.jpg'
import { Parallax } from 'react-parallax'

function Better() {
    return (
        <>
            <section className="black-bg cta-two">
                <Parallax bgImage={BGImg} bgImageAlt="" className="section-padding-lg--top section-padding-lg--bottom" strength={1000}>
                    <div className="container">
                        <div className="cta-two__inner">
                            <h3 className="cta-two__title">Better IT Solutions And Services
                                At Your <span>Fingertips</span></h3>
                            <Link to="/contact" className="thm-btn cta-two__btn"><span>LEarn More</span></Link>
                        </div>
                    </div>
                </Parallax>
            </section>
        </>
    )
}

export default Better