import React from 'react'
import { Link } from 'react-router-dom'

function Need() {
    return (
        <>
            <section className="cta-one">
                <div className="container">
                    <div className="cta-one__inner text-center wow fadeInUp" data-wow-duration="1500ms">
                        <div className="cta-one__circle"></div>
                        <div className="section-title ">
                            <p className="section-title__text">Need Any Technology Solution</p>
                            <h2 className="section-title__title section-title__title--light">Let’s Work Together on Project</h2>
                        </div>
                        <Link to="/contact" className="thm-btn thm-btn--light cta-one__btn"><span>Start Now</span></Link>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Need