import React from 'react'
import Homeslider from '../Menhome/Homeslider'
import Networking from '../Menhome/Networking'
import Provide from '../Menhome/Provide'
// import Counter from '../Menhome/Counter'
// import Latestproject from '../Menhome/Latestproject'
import Better from '../Menhome/Better'
import Trusted from '../Menhome/Trusted'
import Clint from '../Menhome/Clint'
import Team from '../Menhome/Team'
import Benifit from '../Menhome/Benifit'
// import Checkout from '../Menhome/Checkout'
import Need from '../Menhome/Need'

const Home = () => {

    return (
        <>
            <Homeslider />
            <Networking />
            <Provide />
            {/* <Counter /> */}
            {/* <Latestproject/> */}
            <Better />
            <Benifit />

            <Trusted />
            <Clint />

            {/* <Team /> */}
            {/* <Checkout/> */}
            <Need />
        </>
    )
}

export default Home