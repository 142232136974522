import React from 'react'
import { Link } from 'react-router-dom'
import BGImg1 from '../../assets/images/background/slider-1-1.jpg'
import BGImg2 from '../../assets/images/background/slider-1-2.jpg'
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide, } from 'swiper/react';

function Homeslider() {
    const sliderOptions = {
        loop: true,
        items: 1,
        margin: 0,
    }

    return (
        <>
            <div id="home" className="slider-one">
                <Swiper className=""   {...sliderOptions} slidesPerView={1}>
                    <SwiperSlide className="item slider-one__slide-1">
                        <div className="slider-one__bg" style={{ backgroundImage: `url(${BGImg1})` }}>
                        </div>
                        <div className="slider-one__line"></div>
                        <div className="slider-one__shape-1"></div>
                        <div className="slider-one__shape-2"></div>
                        <div className="slider-one__shape-3"></div>
                        <div className="container">
                            <div className="slider-one__content ">
                                <div className="slider-one__floated lettering-text" >technology</div>
                                <p className="slider-one__text">Welcome to best IT Solutions</p>
                                <div className="slider-one__title-wrapper">
                                    <h2 className="slider-one__title">Provide IT Solutions <br />
                                        and Services</h2>
                                </div>
                                <div className="slider-one__btns">
                                    <Link to="/about" className="thm-btn slider-one__btn"><span>Learn More</span></Link>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="item slider-one__slide-2">
                        <div className="slider-one__bg" style={{ backgroundImage: `url(${BGImg2})` }}>
                        </div>
                        <div className="slider-one__line"></div>
                        <div className="slider-one__shape-1"></div>
                        <div className="slider-one__shape-2"></div>
                        <div className="slider-one__shape-3"></div>
                        <div className="container">
                            <div className="slider-one__content ">
                                <div className="slider-one__floated lettering-text">technology</div>
                                <p className="slider-one__text">Welcome to best IT Solutions</p>
                                <div className="slider-one__title-wrapper">
                                    <h2 className="slider-one__title">Provide IT Solutions <br />
                                        and Services</h2>
                                </div>
                                <div className="slider-one__btns">
                                    <Link to="/about" className="thm-btn slider-one__btn"><span>Learn More</span></Link>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </>
    )
}
export default Homeslider